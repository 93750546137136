import ZipCode from "@clayton-homes/home-designer/components/zip-code-dialog";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback } from "react";
import { AnalyticEventNames } from "../../utilities/env";
import { analytics } from "../../utilities/segment";
import ChevronDownIcon from "/assets/svgs/chevron-down.svg";

export default function ZipCodeDialog({
	noBackground,
}: {
	noBackground: boolean;
}) {
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const router = useRouter();

	const createQueryString = useCallback(
		(name: string, value: string) => {
			const params = new URLSearchParams(searchParams?.toString());
			params.set(name, value);
			return params.toString();
		},
		[searchParams],
	);

	return (
		<ZipCode
			onSelect={(zipCode) => {
				analytics?.track(AnalyticEventNames.locationUpdated, {
					zipCode,
				});
				router.replace(`${pathname}?${createQueryString("zipCode", zipCode)}`);
			}}
		>
			<div
				className={`flex h-fit ${
					noBackground ? "text-white" : "text-primary-500"
				}`}
			>
				<span
					className={`${
						noBackground ? "border-b-white" : "border-b-primary-500"
					} h-5 border-b border-b-primary-500`}
				>
					{searchParams?.get("zipCode")}
				</span>
				<ChevronDownIcon className="my-1 ml-1" />
			</div>
		</ZipCode>
	);
}

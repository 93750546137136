"use client";

import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { useState } from "react";
import Menu from "./menu";
import ZipCodeDialog from "./zip-code-dialog";
import Logo from "/assets/svgs/logo.svg";

interface HeaderProps {
	noBackgroundPage: boolean;
	showMenu?: boolean;
}

export default function Header({
	noBackgroundPage,
	showMenu = true,
}: HeaderProps) {
	const searchParams = useSearchParams();
	const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

	const noBackground = noBackgroundPage && !isNavMenuOpen;

	const zipCode = searchParams?.get("zipCode") || "";

	return (
		<header className={`${noBackgroundPage ? "absolute" : ""} w-full`}>
			<div
				className={`${
					noBackground ? "text-white" : "text-gray-700"
				} wrapper-lg text-caption relative flex h-16 justify-between py-4 transition-colors duration-300 2xl:gap-6 2xl:py-2`}
			>
				<div
					className={`${
						noBackgroundPage ? "border-b-0 opacity-0" : "border-b opacity-100"
					} absolute inset-0 z-30 border-b-gray-300 bg-white transition-opacity 2xl:border-b-0 2xl:opacity-0`}
				/>
				<div className="z-50 flex items-center">
					<Link
						href={{
							pathname: "/",
							query: { zipCode: zipCode },
						}}
						className={`${
							noBackground ? "border-r-white" : "border-r-gray-300"
						} mr-4 inline-block h-[46px] border-r py-[5px] pr-4`}
						aria-label="Go to Design Center home page."
					>
						<Logo
							className={`${
								noBackground ? "text-white" : "text-primary-500"
							} h-full w-full transition-colors duration-300`}
						/>
					</Link>
					<div className="flex flex-col">
						<span
							className={`${
								noBackground ? "text-white" : "text-gray-500"
							} text-xs font-semibold`}
						>
							BUILDING NEAR:
						</span>
						<span className="text-caption inline-block text-black">
							<ZipCodeDialog noBackground={noBackground} />
						</span>
					</div>
				</div>
				{showMenu ? (
					<div className={`flex flex-grow items-center justify-end`}>
						<Menu
							noBackgroundPage={noBackgroundPage}
							onMenuOpen={setIsNavMenuOpen}
						/>
					</div>
				) : null}
			</div>
		</header>
	);
}
